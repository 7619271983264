import React from "react";
import { Box } from "@mui/material";
import { colors } from "../utils/const";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
// import { Lazy, Pagination, Navigation } from 'swiper/modules';

export default function VerticalCarousel({
  itemsArray,
  Component,
  slidesPerView,
  bg = colors.bg_grey,
  hideDots,
  center = false,
  container = true,
  VerticalPadding = true,
  flag,
}) {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  let dir = locale === "en" ? "left" : "right";
  let dir2 = locale === "en" ? "right" : "left";

  const isCompanyTestimonials =
    itemsArray?.[0]?.avatar === "/business/hossam-avatar.jpg";
  const p = VerticalPadding ? "vertical-safe-padding" : "";
  const c = center ? "horizontal-safe-padding" : `section-safe-padding-${dir}`;
  return (
    <Box
      className={`${p} ${c}`}
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        bgcolor: bg,
        p: container == false ? "0 !important" : "",
      }}
    >
      <Box
        sx={{
          width: "100%", // change me
          "& .swiper-pagination": {
            maxWidth: { xs: "60%", sm: "80%" },
            textAlign: dir,
            minHeight: "30px", // Ensure it has enough space for all dots
            overflow: "visible",
          },
          "& .swiper-pagination-bullets": {
            minHeight: "130px", // Ensure it has enough space for all dots
            top: {xs:"245px !important",sm:"290px !important"},
            right: "0 !important",
            height:"150px !important"
          },
          "& .swiper-pagination-bullet": {
            bgcolor: "#000",
            display: hideDots ? "none" : "",
          },
          "& .swiper-button-next": {
            position: "absolute",
            zIndex: "100",
            backgroundImage:
              locale === "en"
                ? "url('/forward1-arrow.png')"
                : "url('/back1-arrow.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% auto",
            backgroundPosition: "center",
            [dir2]: {
              xs: "10px !important",
              sm: "20px !important",
              // md: "700px !important",
              // lg: "1000px !important",
              // xl: "1200px !important",
            },
            [dir]: "auto !important",
            top: isCompanyTestimonials ? "94.5%" : "90%",
            width: "40px !important",
            height: "40px !important",
          },

          "& .swiper-button-prev": {
            position: "absolute",
            zIndex: "100",
            backgroundImage:
              locale === "en"
                ? "url('/back1-arrow.png')"
                : "url('/forward1-arrow.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% auto",
            backgroundPosition: "center",
            [dir2]: {
              xs: "60px !important",
              sm: "70px !important",
              // md: "650px !important",
              // lg: "950px !important",
              // xl: "1150px !important"
            },
            [dir]: "auto !important",
            top: isCompanyTestimonials ? "94.5%" : "90%",
            width: "40px !important",
            height: "40px !important",
          },

          "& .swiper-button-next::after": {
            display: "none",
          },
          "& .swiper-button-prev::after": {
            display: "none",
          },
        }}
      >
        <Swiper
          showsPagination={false}
          style={{ paddingBottom: locale=== "en" ? "80px": "100px" } }
          pagination={{
            clickable: true,
          }}
          // slidesPerView={1.3}
          slidesPerView={1.4}
          // spaceBetween={10}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          className="mySwiper"
          breakpoints={{
            // 0: {
            //   slidesPerView: slidesPerView - 2,
            //   spaceBetween: 10,
            // },
            600: {
              slidesPerView: slidesPerView - 2,
              // spaceBetween: 20,
            },
            900: {
              slidesPerView: slidesPerView - 1,
              // spaceBetween: 25,
            },
            1400: {
              slidesPerView: slidesPerView,
              // spaceBetween: 30,
            },
          }}
        >
          {itemsArray?.map(
            (
              {
                avatar,
                name,
                role,
                rating,
                review,
                img,
                imageUrl,
                title,
                id,
                branches,
                type,
                address,
                areaId,
                latitude,
                longitude,
                phoneNumber,
                Title,
                Description,
              },
              index
            ) => (
              <SwiperSlide key={index}>
                <Component
                  // ReviewCard
                  avatar={avatar}
                  name={name}
                  role={role}
                  flag={flag}
                  rating={rating}
                  review={review}
                  // MemberCard
                  img={
                    img ||
                    imageUrl ||
                    (type === "offers" ? "/offer.png" : "/merchant.png")
                  }
                  // PlaceCard
                  title={title} //  merchants api
                  id={id} //  merchants api
                  branches={branches} //  merchants api
                  type={type}
                  // Nearest Branch Card
                  address={address}
                  areaId={areaId}
                  latitude={latitude}
                  longitude={longitude}
                  phoneNumber={phoneNumber}
                  Title={Title}
                  Description={Description}
                ></Component>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </Box>
    </Box>
  );
}
